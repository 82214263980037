import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import useRequest from "kb-gql";
import { useLocation, useParams } from "react-router-dom";
import { dispatch } from "../../redux";
import { typography } from "../../const";
import { modulesQuery } from "../../const/modulesQuery";
import { checkIfIE } from "../../const/helpers";
import { seoQuery } from "../../hooks/useSeo";
import { ContentPadding } from "../../components/Wrapper";
import Image from "../../components/Image";
import Builder from "../../components/modules/Builder";
import { ProjectTitle } from "../../elements";
import Seo from "../partials/Seo";
import ErrorPage from "./ErrorPage";

const queryFields = `
	title
	fields{
		description
		type
		featureImage{
			mediaItemUrl
			altText
		}
    }
    ${seoQuery}
	${modulesQuery("ProjectOrProperty")}
`;

const makeRequest = (slug) => `
    projectOrProperty(id: "${slug}", idType: URI) {
        ${seoQuery}
        ${queryFields}
    }
`;

const previewQuery = (slug, database_id) => `
    query PreviewQuery{
        projectOrProperty(id: "${
          slug === "preview" ? database_id : slug
        }", idType: ${
  /*
   *  check if the slug equals preview, at the time of writing this
   *  any post that is either unsaved or saved as a draft will have the
   *  slug: preview
   */
  slug === "preview" ? "DATABASE_ID" : "URI"
} ) { ${
  /*
   * If the slug does not equals preview we have to query for the "preview"
   * fields in graphql, this is because wp-grapqhl doesn't actually think of it
   * as a preview until it is saved as post and a revision is requested.
   */
  slug !== "preview"
    ? `
        preview{
            node{
                ${queryFields}
            }
        }
        `
    : // If we are querying for a revision we query for the regular fields
      `
            ${queryFields}
        `
}
        }
    }
`;

async function getPreviewData(searchParams, slug) {
  const preview = await fetch(process.env.REACT_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${searchParams.jwtToken}`,
    },
    body: JSON.stringify({
      query: previewQuery(slug, searchParams.database_id),
    }),
  })
    .then((res) => res.json())
    .then(({ data }) => data);
  /*
   * Here we do an if check on existing object properties to determine
   * which data to set in the previewData state
   */
  if (preview?.projectOrProperty?.preview?.node) {
    return preview?.projectOrProperty?.preview?.node;
  } else {
    return preview?.projectOrProperty;
  }
}

const ProjectOrProperty = () => {
  const { slug } = useParams();
  const { state } = useLocation();
  const searchParams = {
    jwtToken: state?.jwtToken,
    database_id: state?.database_id
  };
  const [previewData, setPreviewData] = useState(null);
  const data = useRequest(makeRequest(slug), `projects-${slug}`);
  useEffect(() => {
    dispatch("colorScheme/project");
    //check if there is previewdata available for this post
    getPreviewData(searchParams, slug).then((data) => setPreviewData(data));
  }, []);
  //check if data or previewdata doesn't exist before sending us to the errorpage
  if (data === null && !previewData) return <ErrorPage />;
  if (!data && !previewData) return null;
  if (data === undefined) return null;
  const { description, type, featureImage } =
    previewData?.fields || data.fields;
  return (
    <PageWrapper isIE={checkIfIE()}>
      <Seo pageSeo={data?.seo} />
      <ContentPadding>
        <TitleBlock isIE={checkIfIE()}>
          <p className="type">
            {type === "project" ? "Byggeprosjekt" : "Eiendom"}
          </p>
          <ProjectTitle>{previewData?.title || data?.title}</ProjectTitle>
          <p
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </TitleBlock>
      </ContentPadding>
      <ImageWrapper isIE={checkIfIE()}>
        <Image src={featureImage?.mediaItemUrl} alt={featureImage?.altText} />
      </ImageWrapper>
      <Builder
        modules={previewData?.modules?.builder || data?.modules?.builder}
        pageType="ProjectOrProperty"
      />
    </PageWrapper>
  );
};

export default ProjectOrProperty;

const PageWrapper = styled.div`
  ${(p) =>
    p.isIE &&
    css`
      display: block;
      > * {
        display: block;
      }
    `}

  @media (max-width: 768px) {
    padding-top: 45px;
  }
`;

const TitleBlock = styled.div`
  margin-bottom: 100px;

  > p {
    ${(p) =>
      p.isIE &&
      css`
        display: inline-table;
      `}
  }
  > * {
    display: block;
  }

  p {
    text-align: center;
  }

  p.type {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 22px;
    font-family: ${typography.mono};
  }
  p.description {
    font-size: 26px;
    line-height: 36px;
    font-family: ${typography.canelaTextRegular};
  }
  @media (max-width: 1440px) {
    p.description {
      font-size: 23px;
      line-height: 33px;
    }
  }
  @media (max-width: 1024px) {
    max-width: 720px;
    width: 100%;
    align-self: center;
    text-align: center;
    p.type {
      letter-spacing: 0.05em;
      font-size: 14px;
      line-height: 22px;
    }
    p.description {
      font-size: 23px;
      line-height: 32px;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 37px;
    p.description {
      font-size: 19px;
      line-height: 30px;
    }
    p.type {
      letter-spacing: 0.05em;
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

const ImageWrapper = styled.div`
  margin-bottom: 94px;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 1440px) {
    margin-bottom: 89px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 67px;
    width: 100%;
    left: 0;
    right: 0;
    ${(p) =>
      p.isIE &&
      css`
        height: 100%;
        > * {
          border: 1px solid red;
          margin: 0;
        }
      `}
  }
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;
