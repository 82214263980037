import React, { useEffect } from "react";
import { dispatch } from "../../redux";
import { modulesQuery } from "../../const/modulesQuery";
import useRequest from "kb-gql";
import Builder from "../../components/modules/Builder";
import { useSearchParams, useNavigate } from "react-router-dom";

const homePageQuery = `
	page(id: "home", idType: URI) {
		title
		${modulesQuery("Page")}
	}
`;

const Home = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch("colorScheme/primary");
    //check for preview request of unpublished page or post
    handlePreviewCheck({
      post_type: searchParams.get("post_type"),
      p: searchParams.get("p")
    });
  }, []);

  const data = useRequest(homePageQuery, "/");

  const handlePreviewCheck = (params) => {
    //return if no params are present;
    if (!params) return null;
    /*
     * If there are params present check if the post_type param is there.
     * If it is, we navigate to the proper template and pass the remaining params
     */
    if (params?.post_type && params?.p) {
      let nav;
      const remakeParamString = params.p.split("?");
      switch (params.post_type) {
        case "news":
          nav = `/nyheter/preview`;
          break;
        case "articles":
          nav = `/artikkel/preview`;
          break;
        case "projects_properties":
          nav = `/prosjekter-og-eiendommer/preview`;
          break;
        case "social_duty":
          nav = `/samfunnsansvar/preview`;
          break;
        default:
          nav = `/preview`;
      }
      navigate(nav, {
        replace: true,
        state: {
          database_id: remakeParamString?.[0],
          jwtToken: remakeParamString?.[1].substr(9),
        },
      });
      window.location.reload();
    } else if (params?.page_id && !params?.post_type) {
      let nav;
      const remakeParamString = params?.page_id.split("?");
      nav = `/preview`;
      navigate(nav, {
        replace: true,
        state: {
          database_id: remakeParamString?.[0],
          jwtToken: remakeParamString?.[1].substr(9),
        },
      });
      window.location.reload();
    }
  };

  if (!data) return null;

  return (
    <Builder
      modules={data.modules?.builder}
      pageType="page"
      className="main-content"
    />
  );
};

export default Home;
