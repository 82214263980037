import { AnimatePresence } from "framer-motion";
import React from "react";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import MasterLayout from "./pages/MasterLayoutV2";
import EmployeeIndex from "./pages/templates/EmployeeIndex";
import ErrorPage from "./pages/templates/ErrorPage";
import FeatureArticle from "./pages/templates/FeatureArticle";
import Home from "./pages/templates/Home";
import NewsAndArticleIndex from "./pages/templates/NewsAndArticleIndex";
import NewsArticle from "./pages/templates/NewsArticle";
import Page from "./pages/templates/Page";
import ProjectOrProperty from "./pages/templates/ProjectOrProperty";
import ProjectOrPropertyIndex from "./pages/templates/ProjectOrPropertyIndex";
import PublicationsIndex from "./pages/templates/PublicationsIndex";
import SocialDutiesIndex from "./pages/templates/SocialDutiesIndex";
import SocialDuty from "./pages/templates/SocialDuty";

function Root() {
  return (
    <AnimatePresence mode="wait" initial={true}>
      <MasterLayout>
        <Outlet />
      </MasterLayout>
    </AnimatePresence>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/home",
        element: null,
        loader: async () => redirect("/"),
      },
      {
        path: "prosjekter-og-eiendommer/ocean-space-centre",
        element: null,
        loader: async () =>
          redirect("/prosjekter-og-eiendommer/norsk-havteknologisenter"),
      },
      {
        path: "prosjekter-og-eiendommer",
        element: <ProjectOrPropertyIndex />,
      },
      {
        path: "prosjekter-og-eiendommer/:slug",
        element: <ProjectOrProperty />,
      },
      {
        path: "nyheter",
        element: <NewsAndArticleIndex />,
      },
      {
        path: "nyheter/:slug",
        element: <NewsArticle />,
      },
      {
        path: "artikkel/:slug",
        element: <FeatureArticle />,
      },
      {
        path: "samfunnsansvar",
        element: <SocialDutiesIndex />,
      },
      {
        path: "samfunnsansvar/:slug",
        element: <SocialDuty />,
      },
      {
        path: "publikasjoner",
        element: <PublicationsIndex />,
      },
      {
        path: "publikasjoner/:slug",
        element: <PublicationsIndex />,
      },
      {
        path: "kontakt/ansattsok",
        element: <EmployeeIndex />,
      },
      {
        path: ":slug",
        element: <Page />,
      },
      {
        path: ":parent/:slug",
        element: <Page />,
      },
      {
        path: ":grandparent/:parent/:slug",
        element: <Page />,
      },
    ],
  },
]);

function Router() {
  return <RouterProvider router={router} />;
}

export default Router;
